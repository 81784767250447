import { date, object, ref, string } from 'yup';
import {
  AccoladeAddUpdateType,
  AccoladeOptionType,
  AccoladeType,
  ContractAddUpdateType,
  ContractType,
  ElegibilityAddUpdateType,
  ElegibilityOptionsType,
  ElegibilityType,
  OverlappingDateType,
  OverlappingSessionType,
  PlayerDatabaseType,
  PlayerManagerAddUpdateType,
  PlayerManagerType,
  PlayerSelectedTabEnum,
} from './type';
import { OptionType } from '../../common/fields/type';
import { UserOrganisationType } from '../../store/auth/type';
import { TabOptionType } from '../../common/component/type';
import { capitalize, some } from 'lodash';

export const playerInitialValue = (playerData?: PlayerDatabaseType): PlayerDatabaseType => ({
  created: playerData?.created || '',
  dateOfBirth: playerData?.dateOfBirth || '',
  firstName: playerData?.firstName || '',
  height: playerData?.height || '',
  id: playerData?.id || '',
  juniorTeam: playerData?.juniorTeam || '',
  lastName: playerData?.lastName || '',
  organisationId: playerData?.organisationId || '',
  position: playerData?.position || '',
  preferredFirstName: playerData?.preferredFirstName || '',
  preferredFoot: playerData?.preferredFoot || '',
  preferredLastName: playerData?.preferredLastName || '',
  secondaryPosition: playerData?.secondaryPosition || '',
  state: playerData?.state || '',
  updated: playerData?.updated || '',
  weight: playerData?.weight || '',
});

export const accoladeInitialValue = (accoldaeInfo?: AccoladeType): AccoladeAddUpdateType => ({
  accoladeCategory: accoldaeInfo?.accoladeCategory || '',
  accoladeName: accoldaeInfo?.accoladeName || '',
  season: accoldaeInfo?.season || '',
});

export const playerManagerInitialValue = (
  playerManagerInfo?: PlayerManagerType,
): PlayerManagerAddUpdateType => ({
  agentId: playerManagerInfo?.agent?.id || '',
  endDate: playerManagerInfo?.endDate || '',
  startDate: playerManagerInfo?.startDate || '',
});

export const elegibilityInitialValue = (
  elegibilityInfo?: ElegibilityType,
): ElegibilityAddUpdateType => ({
  elegibilityType: elegibilityInfo?.eligibilityType || '',
  elegiblityStatus: elegibilityInfo?.eligibilityStatus || '',
  season: elegibilityInfo?.season || '',
});

export const contractInitialValue = (contractInfo?: ContractType): ContractAddUpdateType => ({
  contractType: contractInfo?.contractType || '',
  endDate: contractInfo?.endDate || '',
  signingDate: contractInfo?.signingDate || '',
  startDate: contractInfo?.startDate || '',
  team: contractInfo?.team || '',
  startSeason: contractInfo?.startDate || '',
  endSeason: contractInfo?.endDate || '',
  contractEndReason: contractInfo?.contractEndReason || '',
  signingAnnoucementSource: contractInfo?.signingAnnoucementSource || '',
});

export const playerManageValidationSchema = object().shape({
  agentId: string().required('Please select agent'),
  endDate: date()
    .typeError('Please select date')
    .min(ref('startDate'), "End date can't be before start date")
    .required('Please select end date'),
  startDate: date().typeError('Please select date').required('Please select start date'),
});

export const contractValidationSchema = (
  seasonList: OverlappingSessionType[], // seasonList of OverlappingSessionType
) =>
  object().shape({
    contractType: string().required('Please select Contract'),
    endDate: date()
      .typeError('Please select date')
      .min(ref('startDate'), "End date can't be before start date")
      .required('Please select end date'),
    startDate: date().typeError('Please select date').required('Please select start date'),
    startSeason: date()
      .typeError('Please select season date')
      .required('Please select start date')
      .test(
        'startSeason-overlapping',
        'Selected start season overlaps with an existing season',
        function (startSeason) {
          const endSeason = this.resolve(ref('endSeason')) as Date;
          return !seasonList.some((season) => {
            const seasonStart = new Date(season.startSeason);
            const seasonEnd = new Date(season.endSeason);
            return (
              (startSeason >= seasonStart && startSeason <= seasonEnd) ||
              (endSeason >= seasonStart && endSeason <= seasonEnd) ||
              (startSeason <= seasonStart && endSeason >= seasonEnd)
            );
          });
        },
      ),
    endSeason: date()
      .typeError('Please select date')
      .min(ref('startSeason'), "End season can't be before start season")
      .required('Please select end date')
      .test(
        'endSeason-overlapping',
        'Selected end season overlaps with an existing season',
        function (endSeason) {
          const startSeason = this.resolve(ref('startSeason')) as Date;
          return !seasonList.some((season) => {
            const seasonStart = new Date(season.startSeason);
            const seasonEnd = new Date(season.endSeason);
            return (
              (startSeason >= seasonStart && startSeason <= seasonEnd) ||
              (endSeason >= seasonStart && endSeason <= seasonEnd) ||
              (startSeason <= seasonStart && endSeason >= seasonEnd)
            );
          });
        },
      ),
    signingDate: string().required('Please select signing date'),
    team: string().required('Please select team'),
    signingAnnoucementSource: string().required('Please enter Signing Announcement Source'),
  });

export const accoladeValidationSchema = object().shape({
  accoladeCategory: string().required('Please enter Category'),
  accoladeName: string().required('Please enter name'),
  season: string().required('Please enter season'),
});

export const elegibilityValidationSchema = object().shape({
  elegibilityType: string().required('Please enter type'),
  elegiblityStatus: string().required('Please enter status'),
  season: string().required('Please enter season'),
});

export const playerValidationSchema = object().shape({
  firstName: string().required('Please enter first name'),
  lastName: string().required('Please enter last name'),
});

export const transformOrganisationOption = (orgData: UserOrganisationType[]): OptionType[] => {
  if (!orgData) {
    return [];
  }

  return orgData.map(
    (org) =>
      ({
        label: org.organisationTitle,
        value: org.organisationId.toString(),
      } as OptionType),
  );
};

export const transformPlayerList = (data: any): PlayerDatabaseType[] => {
  if (!data) {
    return [];
  }
  return data.map(
    (playerInfo: any) =>
      ({
        created: playerInfo.created,
        dateOfBirth: playerInfo.date_of_birth,
        firstName: playerInfo.first_name,
        height: playerInfo.height,
        id: playerInfo.id,
        juniorTeam: playerInfo.junior_team,
        lastName: playerInfo.last_name,
        organisationId: playerInfo.organisation_id,
        position: playerInfo.position,
        preferredFirstName: playerInfo.preferred_first_name,
        preferredFoot: playerInfo.preferred_foot,
        preferredLastName: playerInfo.preferred_last_name,
        secondaryPosition: playerInfo.secondary_position,
        state: playerInfo.state,
        updated: playerInfo.updated,
        weight: playerInfo.weight,
      } as PlayerDatabaseType),
  );
};

export const updatePlayerTabOption: TabOptionType[] = [
  {
    label: 'Bio',
    value: PlayerSelectedTabEnum.BIO,
  },
  {
    label: 'Contract',
    value: PlayerSelectedTabEnum.CONTRACT,
  },
  {
    label: 'Accolade',
    value: PlayerSelectedTabEnum.ACCOLADE,
  },
  {
    label: 'Player Manager',
    value: PlayerSelectedTabEnum.PLAYER_MANAGER,
  },
  {
    label: 'Elegibility',
    value: PlayerSelectedTabEnum.ELIGIBILITY,
  },
];

export const transformContractData = (data: any): ContractType[] => {
  if (!data) {
    return [];
  }

  return data.map(
    (opt: any) =>
      ({
        id: opt.id,
        team: opt.team_id,
        endDate: opt.end_date,
        signingDate: opt.signing_date,
        startDate: opt.start_date,
        contractType: opt.contract_type,
        startSeason: opt.start_season,
        endSeason: opt.end_season,
      } as ContractType),
  );
};

export const transformAccoladeData = (data: any): AccoladeType[] => {
  if (!data) {
    return [];
  }

  return data.map(
    (opt: any) =>
      ({
        id: opt.id,
        accoladeCategory: opt.accolade_category,
        accoladeName: opt.accolade_name,
        season: opt.season,
      } as AccoladeType),
  );
};

export const transformPlayerManagerData = (data: any): PlayerManagerType[] => {
  if (!data) {
    return [];
  }

  return data.items.map(
    (opt: any, index: number) =>
      ({
        agent: {
          company: {
            id: opt.agent.company.id,
            name: opt.agent.company.name,
          },
          email: opt.agent.email,
          id: opt.agent.id,
          name: `${opt.agent.first_name} ${opt.agent.last_name}`,
          position: opt.agent.position,
        },
        endDate: opt.end_date,
        id: opt.id,
        startDate: opt.start_date,
      } as PlayerManagerType),
  );
};

export const transformElegibilityData = (data: any): ElegibilityType[] => {
  if (!data) {
    return [];
  }

  return data.map(
    (opt: any) =>
      ({
        id: opt.id,
        eligibilityStatus: opt.eligibility_status,
        eligibilityType: capitalize(opt.eligibility_type),
        season: opt.season,
      } as ElegibilityType),
  );
};

export const transformCategoryOption = (data: any): OptionType[] => {
  if (!data) {
    return [];
  }
  return Object.entries(data).map(([key, value]) => ({
    label: value,
    value: key,
  })) as OptionType[];
};

const transformOptions = (data: any): OptionType[] => {
  if (!data) {
    return [];
  }

  return data.map(
    (opt: string) =>
      ({
        label: opt,
        value: opt,
      } as OptionType),
  );
};

const transformNameOption = (
  data: Record<string, string[]> | null,
): Record<string, OptionType[]> | null => {
  if (!data) return null;

  const transformOption = (item: string): OptionType => ({ label: item, value: item });

  return Object.fromEntries(
    Object.entries(data).map(([key, value]) => [key, value.map(transformOption)]),
  );
};

export const transformAccoladeOption = (data: any): AccoladeOptionType => {
  return {
    name: transformNameOption(data),
    category: transformOptions(data ? Object.keys(data) : []),
  };
};

export const filterPlayer = (
  playerList: PlayerDatabaseType[],
  searchText: string,
): PlayerDatabaseType[] => {
  if (searchText === '') {
    return playerList;
  }
  return playerList.filter(
    (player) =>
      `${player.firstName} ${player.lastName}`.toLowerCase().includes(searchText.toLowerCase()) ||
      `${player.preferredFirstName}${player.preferredLastName}`
        .toLowerCase()
        .includes(searchText.toLowerCase()) ||
      player.firstName.toLowerCase().includes(searchText.toLowerCase()) ||
      player.lastName.toLowerCase().includes(searchText.toLowerCase()) ||
      player.preferredFirstName.toLowerCase().includes(searchText.toLowerCase()) ||
      player.preferredLastName.toLowerCase().includes(searchText.toLowerCase()) ||
      player.height.toString().toLowerCase().includes(searchText.toLowerCase()) ||
      player.weight.toString().toLowerCase().includes(searchText.toLowerCase()) ||
      player.position.toLowerCase().includes(searchText.toLowerCase()),
  );
};

export const transformOption = (data: any): OptionType[] => {
  if (!data) {
    return [];
  }
  return data.map(
    (item: any) =>
      ({
        label: item.name,
        value: item.value,
      } as OptionType),
  );
};

const transformElegibilityTypeOption = (data: any): OptionType[] => {
  if (!data) {
    return [];
  }
  return data.map(
    (item: any) =>
      ({
        label: item.value,
        value: item.name,
      } as OptionType),
  );
};

export const transformEligibilityOptions = (data: any): ElegibilityOptionsType => {
  return {
    eligibilityStatus: transformOption(data?.eligibility_status),
    eligibilityType: transformElegibilityTypeOption(data?.eligibility_type),
  };
};

export const transformOptionState = (data: any): OptionType[] => {
  if (!data) {
    return [];
  }
  return data.map(
    (item: any) =>
      ({
        label: item.name,
        value: item.value,
      } as OptionType),
  );
};

export function isDateOverlapping(dateArray: OverlappingDateType[], selectedDate: string) {
  const selectedDateTime = new Date(selectedDate).getTime();

  return some(dateArray, ({ startDate, endDate }) => {
    const startDateUnix = new Date(startDate).getTime();
    const endDateUnix = new Date(endDate).getTime();
    return selectedDateTime >= startDateUnix && selectedDateTime <= endDateUnix;
  });
}

export const trasnformAgentOptions = (data: any): OptionType[] => {
  if (!data) {
    return [];
  }
  return data.items.map(
    (item: any) =>
      ({
        label: `${item.first_name} ${item.last_name}`,
        value: item.id,
      } as OptionType),
  );
};
