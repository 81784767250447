import { OptionType } from '../../common/fields/type';

export enum PLAYER_MANAGER_TAB {
  PLAYER_AGENCIES = 'player_agencies',
  PLAYER_MANAGER = 'player_manager',
  PLAYER_MANAGER_SUMMARY = 'player_manager_summary',
}

export type PlayerAgencyType = {
  id: string;
  name: string;
  website: string;
  phone: string;
  email: string;
  created: string;
  updated: string;
};

export type PlayerManagerListType = {
  id: string;
  company: PlayerAgencyType;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  position: string;
  startDate: string;
  endDate: string;
  created: string;
  updated: string;
};

export type PlayerAllocationInfoType = {
  id: number;
  organisationId: number;
  firstName: string;
  preferredFirstName: string;
  lastName: string;
  preferredLastName: string;
  juniorTeam: string;
  dateOfBirth: string;
  height: number;
  weight: number;
  position: string;
  secondaryPosition: string;
  state: string;
  preferredFoot: string;
  currentAflActive: boolean;
  eligibility: any[];
  currentRosterAllocation: {
    id: number;
    teamId: number;
    startSeason: number;
    endSeason: number;
    contractType: string;
    contractTypeDescription: string;
  };
  currentManagementAllocation: null;
  created: string;
  updated: string;
};

export type PlayerAgentType = {
  id: number;
  company: {
    id: number;
    name: string;
    website: string;
    phone: string;
    email: string;
    created: string;
    updated: string;
  };
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  position: string;
  startDate: string;
  endDate: string;
  created: string;
  updated: string;
};
export type PlayerAllocationType = {
  id: number;
  player: PlayerAllocationInfoType;
  agent: PlayerAgentType;
  startDate: string;
  endDate: string;
  created: string;
  updated: string;
};

export type PlayerAllocationFilterType = {
  agency: string;
  manager: string;
  club: string;
  player: string;
  eligibilityGroups: string;
};

export type PlayerAllocationFilterOptionType = {
  agency: OptionType[];
  manager: OptionType[];
  club: OptionType[];
  player: OptionType[];
  eligibilityGroups: OptionType[];
};
